import { Link } from 'gatsby'
import * as React from 'react'

import { Button } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

const createPageLink = (baseUrl: string, pageNumber: number) => pageNumber <= 1 ? `${baseUrl}` : `${baseUrl}/${pageNumber}`

export interface Props {
  pagenation: {
    pageCount: number
    currentPage: number
    baseUrl: string
  }
}

const Pagenation = ({ pagenation }: Props) => {

  const prevLink = createPageLink(pagenation.baseUrl, pagenation.currentPage - 1)
  const nextLink = createPageLink(pagenation.baseUrl, pagenation.currentPage + 1)

  return (
    <>
      <Grid container spacing={1} style={{ margin: '30px 0' }}>
        <Grid item xs={4} style={{ textAlign: 'center' }}>
          {pagenation.currentPage === 1 ? <Button variant="contained" disabled><b>← 前ページ</b></Button> : <Link to={prevLink} style={{ textDecoration: 'none' }}> <Button variant="contained"><b>← 前ページ</b></Button></Link>}
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'center' }}>
          <span>{`${pagenation.currentPage} / ${pagenation.pageCount}`}</span>
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'center' }}>
          {pagenation.currentPage === pagenation.pageCount ? <Button variant="contained" disabled><b>次ページ →</b></Button> : <Link to={nextLink} style={{ textDecoration: 'none' }}><Button variant="contained"><b>次ページ →</b></Button></Link>}
        </Grid>
      </Grid>
    </>
  )
}

export default Pagenation